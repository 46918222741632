import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Content.css';

const Content = ({ children, className }) => {
  const classes = cx('Content', className);
  return <div className={classes}>{children}</div>;
};

Content.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

export default Content;
