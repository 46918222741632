import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Intro.css';

const Intro = ({ background, className, children, ...props }) => {
  const classes = cx('Intro', `Intro--${background}`, className);
  return (
    <div className={classes} {...props}>
      {children}
    </div>
  );
};

Intro.propTypes = {
  background: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

Intro.defaultProps = {
  background: 'Default'
};

export default Intro;
