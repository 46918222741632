import React from 'react';

import Logo from '../Logo';
import Menu from '../Menu';
import './Header.css';

const Header = () => {
  return (
    <div className="Header">
      <div>
        <Logo />
      </div>
      <div className="Header__Menu">
        <Menu />
      </div>
    </div>
  );
};

export default Header;
