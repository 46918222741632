import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Rooms from './Rooms';
import Overview from './Overview';

const Work = () => {
  return (
    <div>
      <Switch>
        <Route exact path="/work">
          <Overview />
        </Route>
        <Route exact path="/work/rooms">
          <Rooms />
        </Route>
        <Redirect from="/" to="/about" /> />
      </Switch>
    </div>
  );
};

export default Work;
