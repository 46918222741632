import React from 'react';

import { Header } from 'components';
import Intro from './Intro';
import Overview from './Overview';
import Research from './Research';
import Process from './Process';
import Solution from './Solution';
import Basics from './Basics';

const Rooms = () => {
  return (
    <div>
      <Header />

      <Intro />
      <Overview />
      <Research />
      <Process />
      <Solution />
      <Basics />
    </div>
  );
};

export default Rooms;
