import React from 'react';

import { Content, Images } from 'components';
import './Intro.css';

const Intro = () => {
  return (
    <Content>
      <div className="RoomIntro">
        <h2 className="RoomIntro__Heading">
          The new room booking system at Swisscom
        </h2>
        <h5 className="RoomIntro__Heading RoomIntro__Heading--last">
          Simplify searching for free rooms. Enabling faster and better room
          bookings.
        </h5>
        <img
          src={Images.RoomsIntro}
          className="RoomIntro__Image"
          alt="Screenshots"
        />
      </div>
    </Content>
  );
};

export default Intro;
