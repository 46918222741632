import Logo from './Logo.png';
import Plakat from './Plakat.png';
import Rooms from './Rooms.png';
import RoomsIntro from './RoomsIntro.png';
import Sketch from './Sketch.png';
import React from './React.png';
import VSCode from './VSCode.png';
import Research from './Research.jpg';
import Process from './Process.jpg';
import Solution from './Solution.png';
import RelocateIntro from './relocate/Intro.png';
import RelocateMikael from './relocate/Mikael.png';
import RelocateBern from './relocate/Bern.png';
import RelocateOutdoor from './relocate/Outdoor.png';
import RelocateHome from './relocate/Home.png';
import RelocateScreen from './relocate/Screen.png';
import RelocateFriends from './relocate/Friends.png';

export default {
  Logo,
  Plakat,
  Rooms,
  RoomsIntro,
  Sketch,
  React,
  VSCode,
  Research,
  Process,
  Solution,
  RelocateIntro,
  RelocateMikael,
  RelocateBern,
  RelocateOutdoor,
  RelocateHome,
  RelocateScreen,
  RelocateFriends,
};
