import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Images from '../Images';
import './Logo.css';

const Logo = ({ history }) => {
  const redirect = () => history.push('/');
  return (
    <button tabIndex="0" className="LogoLink" onClick={redirect}>
      <img src={Images.Logo} alt="Logo" />
    </button>
  );
};

Logo.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Logo);
