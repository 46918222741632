import React from 'react';

import { Images } from 'components';
import BasicsTool from './BasicsTool';
import './BasicsTools.css';

const BasicsTools = () => {
  const tools = [
    {
      title: 'React',
      image: Images.React,
      imageHeight: 84,
    },
    {
      title: 'Sketch',
      image: Images.Sketch,
      imageHeight: 90,
    },
    {
      title: 'VSCode',
      image: Images.VSCode,
      imageHeight: 84,
    },
  ];

  const renderTool = ({ title, image, imageHeight }) => {
    return (
      <BasicsTool
        key={title}
        title={title}
        image={image}
        imageHeight={imageHeight}
      />
    );
  };

  return (
    <div className="BasicsTools">
      <h5 className="BasicsTools__Title">Tools</h5>
      <div className="BasicsTools__Items">{tools.map(renderTool)}</div>
    </div>
  );
};

export default BasicsTools;
