import React from 'react';

import { Content, Images } from 'components';
import './Process.css';

const Process = () => {
  return (
    <div className="Process">
      <Content className="Process__Content">
        <>
          <h2>Implementation</h2>
          <div className="Process__ImageBox">
            <div>
              <img
                src={Images.Process}
                alt="Process"
                className="Process__Image"
              />
            </div>
          </div>
          <p>
            We worked in 2 week sprints during the entire implementation. Our
            first goal was to develop a simple, working Mobile Web App to get
            feedback from our users as quickly as possible.
          </p>
          <p>
            At the beginning we realized design and implementation in the same
            sprint. For each feature we have designed several versions. We
            challenged the different versions in the team and gave each other
            feedback until we found the best solution. We noticed that we had to
            wait too long for each other and the progress was not optimal.
          </p>
          <p>
            After 6 sprints we started to bring the design forward by one sprint
            and implement it in the next sprint. This has led to a faster
            implementation, but the valuable discussion and the common
            confrontation with UX and design has become less.
          </p>
          <p>
            It was a tightrope walk between implementing even more features or
            implementing them according to UX best practices.
          </p>
          <p>
            In the end, however, we were able to create a highly satisfactory
            solution for the users. This was possible because we regularly
            collected user feedback through user testing and incorporated it
            into further development.
          </p>
        </>
      </Content>
    </div>
  );
};

export default Process;
