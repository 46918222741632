import React from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import './WorkTile.css';

const WorkTile = ({ intro, date, title, text, image, onClick }) => {
  return (
    <div className="WorkTile">
      <div className="WorkTile__Content">
        <div className="WorkTile__Intro">
          <span className="caption">{intro}</span>
          <span className="WorkTileIntro__Divider" />
          <span className="caption">{date}</span>
        </div>
        <h3>{title}</h3>
        {text}
        <div className="WorkTile__Button">
          <Button label="View Case Study" onClick={onClick} />
        </div>
      </div>

      <button tabIndex="0" className="WorkTile_ImageLink" onClick={onClick}>
        <img src={image} className="WorkTile__Image" alt="Work" />
      </button>
    </div>
  );
};

WorkTile.propTypes = {
  date: PropTypes.string.isRequired,
  intro: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default WorkTile;
