import React from 'react';

import { Content } from 'components';
import BasicsItems from './BasicsItems';
import BasicsTools from './BasicsTools';
import './Basics.css';

const Basics = () => {
  return (
    <div className="Basics">
      <Content>
        <>
          <BasicsItems />
          <BasicsTools />
        </>
      </Content>
    </div>
  );
};

export default Basics;
