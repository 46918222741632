import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './MenuLink.css';

const MenuLink = ({ to, label }) => {
  return (
    <div>
      <NavLink to={to} className="MenuLink" activeClassName="MenuLink--active">
        <div className="MenuLink__Label">{label}</div>
      </NavLink>
    </div>
  );
};

MenuLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default MenuLink;
