import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Button.css';

const Button = ({ label, onClick, className }) => {
  const classes = cx('Button', className);
  return (
    <button onClick={onClick} className={classes}>
      <span>{label}</span>
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default Button;
