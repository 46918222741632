import React, { useEffect, useRef } from 'react';

import { useAnimation } from 'hooks';

const start = 73;
const end = 107;
const duration = 2000;
let counter = 1;

const Mouse = ({ width, fill, animate }) => {
  const [progress, reset] = useAnimation(duration);
  const timer = useRef(null);

  useEffect(() => {
    const startTimer = () => {
      timer.current = setInterval(() => {
        reset();
        counter >= 7 ? stopTimer() : counter++;
      }, duration);
    };

    const stopTimer = () => {
      clearInterval(timer.current);
    };

    if (animate) {
      counter = 1;
      startTimer();
      return () => stopTimer;
    }
  }, [animate, reset]);

  const resetAnimation = () => {
    if (animate && counter === 4 && progress === 1) {
      reset();
    }
  };

  const calculateStart = () => {
    return progress <= 0.5
      ? start + start * progress
      : start + start * 0.5 - start * (progress - 0.5);
  };

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 195 288"
        width={width}
        onMouseEnter={resetAnimation}
      >
        <path
          fill={fill}
          d="M97.66.2c49.03 0 96.05 45.18 96.99 94.99l.01 1.51v94c0 53.5-43.5 97-97 97-52.96 0-96.13-42.63-96.98-95.4l-.02-1.6v-94C.66 46.3 48.13.2 97.66.2zm0 17.7c-42.96 0-78.09 34.6-78.79 77.5v95.3c0 43.4 35.4 78.8 78.8 78.8 42.96 0 78.08-34.6 78.78-77.5l.01-1.3v-94c0-43.4-35.4-78.8-78.8-78.8z"
        />
        <line
          x1="97.65"
          y1={animate ? calculateStart() : start}
          x2="98.25"
          y2={end}
          stroke={fill}
          strokeWidth="18.25"
          strokeLinecap="round"
        ></line>
      </svg>
    </>
  );
};

export default Mouse;

Mouse.defaultProps = {
  width: '40px',
  animate: true,
  fill: '#222',
};
