import React from 'react';

import BasicsItem from './BasicsItem';
import './BasicsItems.css';

const BasicsItems = () => {
  const items = [
    {
      header: 'My Role',
      text: 'Lead Frontend Developer',
      text2: 'Interaction Designer'
    },
    {
      header: 'Duration',
      text: 'one Year'
    },
    {
      header: 'Plattform',
      text: 'Mobile, Desktop'
    }
  ];

  const renderItem = ({ header, text, text2 }) => {
    return <BasicsItem key={header} header={header} text={text} text2={text2} />;
  };

  return <div className="BasicsItems">{items.map(renderItem)}</div>;
};

export default BasicsItems;
