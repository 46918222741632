import React from 'react';

import { Images } from 'components';
import './Solution.css';

const Solution = () => {
  return (
    <div className="Solution">
      <img src={Images.Solution} alt="Calendar" className="Solution__Image" />
    </div>
  );
};

export default Solution;
