import React from 'react';

import MenuLink from './MenuLink';
import './Menu.css';

const Menu = () => {
  const items = [
    {
      label: 'About',
      link: '/about',
    },
    {
      label: 'Work',
      link: '/work',
    },
  ];

  const renderItem = ({ label, link }) => {
    return (
      <li key={link} className="MenuItem">
        <MenuLink to={link} label={label} />{' '}
      </li>
    );
  };

  return <ul className="Menu">{items.map(renderItem)}</ul>;
};

export default Menu;
