import React from 'react';

import { Header, Heart } from 'components';
import './About.css';

const About = () => {
  return (
    <div>
      <Header />
      <div className="About">
        <div className="About__Content">
          <h1 className="About__Hero">Hello.</h1>
          <h2 className="About__Intro">
            I'm Marc, currently working as a web developer.
          </h2>
          <h5 className="About__Text">
            I <Heart style={{ width: '0.9em', verticalAlign: '-.16em' }} />{' '}
            minimalistic websites with an attention to typography.
          </h5>
        </div>
      </div>
    </div>
  );
};

export default About;
