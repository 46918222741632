import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './BasicsTool.css';

const BasicsTool = ({ title, image }) => {
  const imageClasses = cx('BasicsTool__Image', `BasicsTool--${title}`);

  return (
    <div className="BasicsTool">
      <img src={image} alt={title} className={imageClasses} />
      <div className="BasicsTool__Title">{title}</div>
    </div>
  );
};

BasicsTool.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageHeight: PropTypes.number.isRequired
};

export default BasicsTool;
