import React from 'react';

import { Images, Content } from 'components';
import './Research.css';

const Research = (props) => {
  return (
    <div className="Research">
      <Content className="Research__Content">
        <>
          <h2 className="Research__Title">Research</h2>
          <div className="Research__Box">
            <div>
              <h5 className="Research__SubTitle">Understanding room booking</h5>
              <div className="Research__Text">
                With the help of interviews and the feedback platform{' '}
                <a href="https://www.looping.com/">looping.com</a> we were able
                to establish a consistent list of pain points. Our main goal was
                to understand three things:
              </div>
              <ul className="Research__PainPoints">
                <li>- Room booking habits and challenges</li>
                <li>- Which personas are involved in the system</li>
                <li>- Motivation to use a room booking system</li>
              </ul>
            </div>
            <div>
              <img
                src={Images.Research}
                alt="Research"
                className="Research__Image"
              />
            </div>
          </div>
        </>
      </Content>
    </div>
  );
};

export default Research;
