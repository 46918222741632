import React from 'react';

import { Content } from 'components';
import './Overview.css';

const Overview = () => {
  return (
    <div className="Overview">
      <Content className="Overview__Content">
        <>
          <h2 className="Overview__Title">Overview</h2>
          <h5 className="Overview__SubTitle">
            Swisscom has over 20,000 employees who often meet in one place
            despite digital networking, and it is crucial that a free meeting
            room can be found quickly and easily.
          </h5>
          <div>
            The old room booking system was not easy to use, unpopular and was
            bypassed if possible. The stakeholders at Swisscom were looking to
            make room bookings more appealing to the employees and to equip them
            with a digital tool that they like, thereby improving the
            utilisation and management of the rooms.
          </div>
        </>
      </Content>
    </div>
  );
};

export default Overview;
