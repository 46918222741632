import React from 'react';
import PropTypes from 'prop-types';

import './BasicsItem.css';

const BasicsItem = ({ header, text, text2 }) => {
  return (
    <div className="BasicsItem" key={header}>
      <h5>{header}</h5>
      <div>{text}</div>
      {text2 && <div>{text2}</div>}
    </div>
  );
};

BasicsItem.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  text2: PropTypes.string
};

export default BasicsItem;
