import React from 'react';
import { useHistory } from 'react-router-dom';

import { Header, Content, WorkTile, Images } from 'components';
import { content } from './Overview.module.css';

const workItems = [
  {
    intro: 'Progressive Web Application',
    date: '2017 - 2018',
    title: 'Enabling lightweight room booking',
    text:
      "A digital platform simplifying room booking at Swisscom's facilities.",
    link: '/work/rooms',
  },
];

const Overview = () => {
  let history = useHistory();

  const renderWorkItem = ({ intro, date, title, text, link }) => {
    const props = {
      key: title,
      intro,
      date,
      title,
      text,
      image: Images.Plakat,
      onClick: () => history.push(link),
    };

    return <WorkTile {...props} />;
  };

  return (
    <>
      <Header />
      <Content className={content}>{workItems.map(renderWorkItem)}</Content>
    </>
  );
};

export default Overview;
