import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

import { Work, School, About } from './pages';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/work">
          <Work />
        </Route>

        <Route path="/school">
          <School />
        </Route>

        <Route exact path="/about">
          <About />
        </Route>

        <Redirect from="/" to="/about" />
      </Switch>
    </BrowserRouter>
  );
};

export default App;
